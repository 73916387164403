import React from 'react'
import { Link } from 'react-router-dom'
import Div from '../Div'
import './card.scss'

export default function Card({title, link, src, alt}) {
  const titles = {
    webDevelopment: "Web Development",
    mobileAppDevelopment: "Mobile App Development",
    digitalMarketing: 'Digital Marketing',
    gameDevelopment: 'Game Development',
    graphicDesigning : 'Graphic Designing',
    creativeDesign: 'Creative Design',
    reactJS: 'React JS',
    laravel: 'Laravel',
    reactNative: 'React Native',
    flutter : 'Flutter'
  }
  return (
    <Link to={`${link}/${title}`} className="cs-card cs-style1">
      <>
        <img src={src} alt={alt} />
        <Div className="cs-card_overlay" />
        <Div className="cs-card_info">
          <span className=" cs-hover_layer3 cs-accent_bg" />
          <h2 className="cs-card_title">{titles[title]}</h2>
        </Div>
      </>
    </Link>
  )
}
