import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

export default function ServiceDetailsPage({title}) {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const serviceContent = {
    webDevelopment: {title: 'Web Development' ,detail: 'At Devop360, we specialize in crafting custom websites that not only look stunning but also perform seamlessly across all devices. Our web development services cover everything from responsive design and e-commerce solutions to CMS integration and SEO optimization. We ensure that your website is secure, easy to navigate, and built to meet your unique business needs. Whether you need a new website or ongoing maintenance and support, our team is here to deliver exceptional results. Contact us today to start your web development journey and elevate your online presence.'},
    mobileAppDevelopment: {title : 'Mobile App Development' , img1: "/images/Mobile-app1.png", img2: "/images/Mobile-app2.png", img3: "/images/Mobile-app3.png", detail: 'At Devop360, we offer expert mobile app development services that bring your ideas to life on iOS and Android platforms. Our team specializes in creating intuitive, user-friendly apps with engaging designs and robust functionality. We cover the full app development lifecycle, from concept and design to coding, testing, and deployment. Whether you need a consumer-facing app, a business solution, or an innovative startup product, we deliver high-quality, scalable, and secure mobile apps that meet your unique goals. Reach out to us to start building an app that connects with users and drives your business forward.'},
    digitalMarketing: {title : 'Digital Marketing', detail: "At Devop360, we specialize in comprehensive digital marketing services that elevate your brand's online presence and drive measurable results. Our team crafts tailored strategies encompassing SEO, content marketing, social media management, email campaigns, and PPC advertising. By combining data-driven insights with creative approaches, we ensure that your digital marketing efforts attract, engage, and convert your target audience. Connect with us to enhance your online visibility and achieve your marketing goals."},
    gameDevelopment: {title : 'Game Development' , detail: "Devop360 offers innovative game development services that bring your gaming concepts to life. Our experienced developers and designers excel in creating engaging and immersive games for various platforms, including mobile, PC, and consoles. From game design and character development to coding and quality assurance, we cover every aspect of the game development process. Whether you're looking to create a casual mobile game or a complex multiplayer experience, we're here to turn your vision into a captivating reality."},
    graphicDesigning : {title : 'Graphic Designing', img1: "/images/logo1.webp", img2: "/images/logo2.webp", img3: "/images/logo3.webp", detail: "At Devop360, we provide top-notch graphic design services that visually represent your brand and captivate your audience. Our designers are experts in creating striking visuals, including logos, branding materials, marketing collateral, and digital graphics. We combine creativity with a deep understanding of design principles to deliver graphics that align with your brand identity and communicate your message effectively. Partner with us for graphic designs that make a lasting impression."},
    creativeDesign: {title : 'Creative Design', detail: "Transform your brand with creative design services from Devop360. We offer a holistic approach to design, incorporating innovation, aesthetics, and functionality. Our services include brand identity design, packaging design, web design, and more. Our designers work closely with you to understand your brand's personality and goals, creating unique designs that resonate with your target audience. Let us help you create a visual identity that stands out in a crowded market."},
    reactJS: {title : 'React JS', detail : "Devop360 provides expert React JS development services to build dynamic and interactive web applications. Our developers leverage the power of React JS to create scalable, high-performance applications with exceptional user experiences. Whether you need a single-page application, a complex frontend for a larger system, or an interactive website, we have the expertise to deliver robust solutions. Contact us to discuss your React JS development needs and take your web applications to the next level."},
    laravel: {title : 'Laravel', detail: "Experience the benefits of modern web development with Devop360's Laravel services. Our team specializes in creating robust and scalable applications using the Laravel framework. We build custom applications that are secure, maintainable, and feature-rich. Whether you need a simple website or a complex web application, our Laravel developers ensure seamless functionality and a smooth user experience. Let's work together to bring your Laravel projects to life."},
    reactNative: {title : 'React Native' , detail: "Devop360 offers React Native development services for cross-platform mobile applications. With React Native, we build apps that run smoothly on both iOS and Android devices, reducing development time and costs. Our developers create responsive and high-performance apps that meet your business objectives. If you're looking for a cost-effective solution for mobile app development without compromising quality, our React Native services are the perfect choice."},
    flutter : {title : 'Flutter' , detail: "Unlock the potential of cross-platform development with Devop360's Flutter services. Our team specializes in building mobile applications using Flutter, Google's open-source framework. We create beautiful and high-performance apps that work seamlessly on both iOS and Android platforms. Whether you're building a new app or porting an existing one, our Flutter development services offer flexibility, speed, and cost-efficiency. Reach out to us to discover how Flutter can benefit your mobile app project."}
  }
  // console.log
  return (
    <>
      <PageHeading
        title='Service Details'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText={serviceContent[params.serviceName].title}
      />
      {/* <Spacing lg='145' md='80' />
      <Div className="container">
        <SectionHeading
          title='Design working process'
          subtitle='Our Process'
          variant='cs-style1 text-center'
        />
        <Spacing lg='90' md='45' />
        <Div className="row">
          <Div className="col-lg-4">
            <IconBox
              icon='/images/square.png'
              title='Strategy & Research'
              subtitle='Groundbreaking campaigns begin with meticulous planning. We delve deep into market trends and audience insights to formulate strategies that ensure your success.'
            />
            <Spacing lg='30' md='30' />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/circle.png'
              title='Wireframing'
              subtitle='Blueprints for Innovation. We meticulously craft wireframes, laying the foundation for seamless user experiences and intuitive interface design.'
            />
            <Spacing lg='30' md='30' />
          </Div>
          <Div className="col-lg-4">
            <IconBox
              icon='/images/triangle.png'
              title='Prototyping'
              subtitle='Bringing Ideas to Life. We create interactive prototypes, allowing you to experience your vision firsthand and refine it for optimal user engagement.'
            />
            <Spacing lg='30' md='30' />
          </Div>
        </Div>
      </Div>
      <Spacing lg='120' md='50' /> */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title={serviceContent[params.serviceName].title}
              subtitle="About Our Services"
            >
              <Spacing lg="30" md="30" />
              <Spacing lg="25" md="40" />
            </SectionHeading>
            <Spacing lg="20" md="20" />
            {/* <p className="">
              Devop360, which was created in 2015, possesses the knowledge,
              ompetence, and achievements necessary to build a vast and
              comprehensive portfolio of successes. The web and mobile app
              development company has through numerous stages of change
              since its beginning in order to enhance and excel at
              whatever web app development services
            </p> */}
            <p><q>{serviceContent[params.serviceName].detail}</q></p>

            <Div className="cs-separator cs-accent_bg"></Div>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_1.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      <Div className="container">
        <Div className="row align-items-center">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-radius_15 cs-shine_hover_1">
              <img src="/images/service_img_1.jpeg" alt="Service" className='cs-radius_15 w-100' />
            </Div>
            <Spacing lg='0' md='40' />
          </Div>
          <Div className="col-lg-6 offset-xl-1">
            <h2 className="cs-font_50 cs-m0">our most related services</h2>
            <Spacing lg='50' md='30' />
            <Div className="row">
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Web Development' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='eCommerce Website' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Landing page' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Mobile App' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Game Development' variant='cs-type2' />
                <Spacing lg='20' md='10' />

              </Div>
              <Div className="col-lg-6">
                <Button btnLink='/service/service-details' btnText='Illustration' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Creative design' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Digital Marketing' variant='cs-type2' />
                <Spacing lg='20' md='10' />
                <Button btnLink='/service/service-details' btnText='Banner, brochure, card' variant='cs-type2' />
                <Spacing lg='20' md='10' />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <TestimonialSlider />
      <Spacing lg='145' md='80' />
      <Div className="container cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-5 col-lg-6">
              <SectionHeading
                title='Some pre questions and answers'
                subtitle='FAQ’s'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className="col-lg-6 offset-xl-1">
              <Accordion />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title='Were delivering the best<br>
          customer experience'
          btnText='Apply For Meeting'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
