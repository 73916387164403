import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import PricingTableList from '../PricingTable/PricingTableList'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import Hero8 from '../Hero/Hero8'

export default function ServicesPage() {
  pageTitle('Service');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {/* <PageHeading
        title='Services'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='Services'
      /> */}
      <Hero8
        title="A LEADING WEB & SOFTWARE COMPANY"
        subtitle="Devop360 established in the year 2015 has all the experience, expertise, and achievements that give the company a vast and diversified portfolio of success. Since its inception, the company has been in varied phases of transformations for betterment and excellence in whatever services we offer. Protruding its list of clients both local and international, Advent Interactive is proud to have worked for a varied jack of projects related to different industries such as hospitality, medicine, education, entertainment, non-profit organizations, and more. We have people crafted with experience, knowledge, and the right skills to do your job in the best possible manner."
        btnLink="/contact"
        btnText="Let’s talk"
        socialLinksHeading="Follow Us"
        // heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/background.jpg"
        bannerHighlightImage="/images/favicon.png"
        spiningCircleUrl="/images/hero_img.svg"
        videosrc ='/video/service-bg.mp4'
      />
      <Spacing lg='150' md='80' />
      <Div className='cs-shape_wrap_4'>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title='Services we can help you with'
                subtitle='What Can We Do'
              />
              <Spacing lg='90' md='45' />
            </Div>
            <Div className='col-xl-8'>
              <Div className='row'>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='webDevelopment'
                    link='/servicedetailpage'
                    src='/images/service_4.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='mobileAppDevelopment'
                    link='/servicedetailpage'
                    src='/images/App Development.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='digitalMarketing'
                    link='/servicedetailpage'
                    src='/images/Digital Marketing.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='gameDevelopment'
                    link='/servicedetailpage'
                    src='/images/Game.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='graphicDesigning'
                    link='/servicedetailpage'
                    src='/images/graphic-design.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='creativeDesign'
                    link='/servicedetailpage'
                    src='/images/service_6.jpeg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                  
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='reactJS'
                    link='/servicedetailpage'
                    src='/images/React.png'
                    alt='React JS Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='laravel'
                    link='/servicedetailpage'
                    src='/images/laravel.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='reactNative'
                    link='/servicedetailpage'
                    src='/images/React Native.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='flutter'
                    link='/servicedetailpage'
                    src='/images/Flutter.png'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30' />
                </Div>
                
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80' />
      <Div className="container">
        <SectionHeading
          title='Providing best <br/>pricing for client'
          subtitle='Pricing & Packaging'
        />
        <Spacing lg='85' md='40' />
        <PricingTableList />
      </Div>
      <Spacing lg='125' md='55' />
      <TestimonialSlider />
      <Spacing lg='150' md='80' />
      <Div className="container">
        <Cta
          title='Were delivering the best <br>customer experience'
          btnText='Apply For Meeting'
          btnLink='/contact'
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
