import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Div from '../Div';
// import { motion, useAnimate, useMotionValueEvent, useScroll } from "framer-motion"

export default function VideoModal({ videoSrc, bgUrl, variant }) {
  const [iframeSrc, setIframeSrc] = useState('about:blank');
  const [toggle, setToggle] = useState(false);
  const handelClick = () => {
    const video = videoSrc.split('?v=')[1].trim();
    setIframeSrc(`https://www.youtube.com/embed/${video}`);
    setToggle(!toggle);
  };
  const handelClose = () => {
    setIframeSrc('about:blank');
    setToggle(!toggle);
  };
  const ref = useRef();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [videoScale, setVideoScale] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [style,setStyle] = useState({ backgroundImage: `url(${bgUrl})` });
  useEffect(() => {
    if(scrollPosition > 4500){
      setStyle({ backgroundImage: `url(${bgUrl})` , transform : `scale(1)` , transition :  'transform 1s' })
    }
    else{
      setStyle({ backgroundImage: `url(${bgUrl})` , transform : `scale(0.4)` , transition :  'transform 1s' })
    }
  }, [scrollPosition]);

  return (
    <>
      <Div
        className={`cs-video_block ${
          variant ? variant : 'cs-style1'
        } cs-video_open cs-bg`}
        style={style}
        onClick={handelClick}
        myvideo='yes'
        ref={ref}
      >
        <span className="cs-player_btn cs-accent_color">
          <span />
        </span>
      </Div>
      <Div className={toggle ? 'cs-video_popup active' : 'cs-video_popup'}>
        <Div className="cs-video_popup_overlay" />
        <Div className="cs-video_popup_content">
          <Div className="cs-video_popup_layer" />
          <Div className="cs-video_popup_container">
            <Div className="cs-video_popup_align">
              <Div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src={iframeSrc}
                  title="video modal"
                />
              </Div>
            </Div>
            <Div className="cs-video_popup_close" onClick={handelClose} />
          </Div>
        </Div>
      </Div>
    </>
  );
}
