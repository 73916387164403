import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Div from '../Div';
import './logolist.scss';
const partnerLogos = [
  {
    src: '/images/partner1.png',
    alt: 'Partner',
  },
  {
    src: '/images/partner2.png',
    alt: 'Partner',
  },
  {
    src: '/images/partner3.png',
    alt: 'Partner',
  },
  {
    src: '/images/partner4.png',
    alt: 'Partner',
  },
  {
    src: '/images/partner5.png',
    alt: 'Partner',
  },
];
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1201 },
    items: 4,
    slidesToSlide: 1 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1200, min: 464 },
    items: 2,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};


export default function LogoList() {
  return (
    // <Div className="cs-partner_logo_wrap">
    //   {partnerLogos.map((partnerLogo, index) => (
    //     <div className="cs-partner_logo" key={index}>
    //       <img src={partnerLogo.src} alt={partnerLogo.alt} />
    //     </div>
    //   ))}
    // </Div>
    <Carousel
    className="cs-partner_logo_wrap"
        swipeable={true}
        draggable={true}
        // showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={ true }
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all 1s"
        transitionDuration={1000}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        // deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
>
        {partnerLogos.map((partnerLogo, index) => (
        <div className="cs-partner_logo" key={index}>
          <img src={partnerLogo.src} alt={partnerLogo.alt} />
        </div>
      ))}
    </Carousel>
  );
}
